import ContohList from "./features/contoh-list/ContohList";
import ContohList2 from "./features/contoh-list/ContohList2";
import LatihanForm from "./features/latihan-form/LatihanForm";
import LatihanForm2 from "./features/latihan-form/LatihanForm2";


function App() {


  return (
    <>
      <div className="App">
        <LatihanForm2/>
      </div>
    </>
  );
}

export default App;